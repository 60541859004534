export const METHODS = {
    ADD: 'add',
    EDIT: 'edit',
    REMOVE: 'remove',
    FROM_CART: 'from_cart',
    FROM_QUOTATION: 'from_quotation',
    FROM_REQUEST: 'from_request',
    FROM_INCOMING: 'from_incoming',
    ADD_ITEM: 'add_item',
    EDIT_ITEM: 'edit_item',
    REMOVE_ITEM: 'remove_item',
    CANCEL: 'cancel',
    ACK: 'ack',
    SEND: 'send',
    PREVIEW: 'preview',
    COPY: 'copy',
    LOAD_TO_CART: 'load_to_cart',
    SHARE: 'share',
    SAVE: 'save',
    SAVE_AS: 'save_as',
    ADD_GROUP: 'add_group',
    EDIT_GROUP: 'edit_group',
    REMOVE_GROUP: 'remove_group',
    EDIT_RULE: 'edit_rule',
    REMOVE_RULE: 'remove_rule',
    ADD_OPTION: 'add_option',
    EDIT_OPTION: 'edit_option',
    REMOVE_OPTION: 'remove_option',
    ADD_OPTION_VALUE: 'add_option_value',
    REMOVE_OPTION_VALUE: 'remove_option_value',
    EDIT_BARCODE: 'edit_barcode',
    REMOVE_BARCODE: 'remove_barcode',
    EDIT_HTML: 'edit_html',
    EDIT_QUANTITY_UNITS: 'edit_quantity_units',
    EDIT_PARAM: 'edit_param',
    REMOVE_PARAM: 'remove_param',
    EDIT_SPECIAL_PRICE: 'edit_special_price',
    REMOVE_SPECIAL_PRICE: 'remove_special_price',
    EDIT_CUSTOM_CODE: 'edit_custom_code',
    REMOVE_CUSTOM_CODE: 'remove_custom_code',
    EDIT_COMPONENT: 'edit_component',
    REMOVE_COMPONENT: 'remove_component',
    ADD_REPLACEMENT: 'add_replacement',
    REMOVE_REPLACEMENT: 'remove_replacement',
    ADD_RELATIVE: 'add_relative',
    REMOVE_RELATIVE: 'remove_relative',
    LOGIN: 'login',
    CHANGE_PASSWORD: 'change_password',
    RESET_PASSWORD: 'reset_password',
    FORGOTTEN_PASSWORD: 'forgotten_password',
    ACTIVATE: 'activate',
    ADD_TYPE: 'add_type',
    EDIT_TYPE: 'edit_type',
    REMOVE_TYPE: 'remove_type',
    ADD_LINE: 'add_line',
    EDIT_LINE: 'edit_line',
    REMOVE_LINE: 'remove_line',
    ACK_RECEIVE_LINE: 'ack_receive_line',
    ACK_DISPATCH_LINE: 'ack_dispatch_line',
    ISSUE_INVOICES: 'issue_invoices',
    ADD_INTERNAL: 'add_internal',
    REMOVE_INTERNAL: 'remove_internal',
    INTERNAL_ADD_LINE: 'internal_add_line',
    INTERNAL_REMOVE_LINE: 'internal_remove_line',
    MERGE_WITH: 'merge_with',
    ADD_RESERVATION: 'add_reservation',
    REMOVE_RESERVATION: 'remove_reservation',
};

export const CLASSES = {
    ORDER: 'order',
    REQUEST: 'request',
    QUOTATION: 'quotation',
    CART: 'cart',
    DISCOUNT: 'discount',
    PRODUCT: 'product',
    TRANSFER: 'transfer',
    INVOICE: 'invoice',
    USER: 'user',
    CUSTOMER: 'customer',
    COMPLAINT: 'complaint',
    NOTES: 'notes',
    NEWS: 'news',
    INVENTORY: 'inventory',
    SHIPMENT: 'shipment',
    EVENT: 'event',
    BACKUP: 'backup',
    ASSIGNMENT: 'assignment',
};

export const ARGS = {
    ID_CUSTOMER: 'id_customer',
    ID_INVOICE_ADDRESS: 'id_invoice_address',
    ID_DELIVERY_ADDRESS: 'id_delivery_address',
    ID_TRANSPORT_TYPE: 'id_transport_type',
    ID_PAYMENT_METHOD: 'id_payment_method',
    ID_USER: 'id_user',
    ID_ITEM: 'id_item',
    ID_MANUFACTURER: 'id_manufacturer',
    ID_PRODUCT_GROUP: 'id_product_group',
    ID_DISCOUNT_GROUP: 'id_discount_group',
    ID_PARAMETER: 'id_parameter',
    CUSTOMER_REFERENCE: 'customer_reference',
    IS_DIVISIBLE: 'is_divisible',
    IS_CRITICAL: 'is_critical',
    ID_DST_STOCK: 'id_dst_stock',
    NOTICE: 'notice',
    CUSTOMER_NOTICES: 'customer_notices',
    DEALER_NOTICES: 'dealer_notices',
    CUSTOMER_NOTICE: 'customer_notice',
    DEALER_NOTICE: 'dealer_notice',
    ID_CART_ITEM: 'id_cart_item',
    ID_REQUEST_ITEM: 'id_request_item',
    ID_QUOTATION_ITEM: 'id_quotation_item',
    ID_ORDER_ITEM: 'id_order_item',
    ID_TRANSFER_ITEM: 'id_transfer_item',
    ID_STOCK_ITEM: 'id_stock_item',
    ID_INVOICE_ITEM: 'id_invoice_item',
    ID_ORDER: 'id_order',
    ID_QUOTATION: 'id_quotation',
    ID_REQUEST: 'id_request',
    ID_PRODUCT: 'id_product',
    ID_FORM: 'id_form',
    ID_WHAT: 'id_what',
    ID_WHERE: 'id_where',
    ID_STATUS: 'id_status',
    QUANTITY: 'quantity',
    PACKAGE_QUANTITY: 'package_quantity',
    QUANTITY_DIV: 'quantity_div',
    QUANTITY_UNIT: 'quantity_unit',
    QUANTITY_MIN: 'quantity_min',
    QUANTITY_BULK: 'quantity_bulk',
    QUANTITY_PALLET: 'quantity_pallet',
    UNIT_PRICE: 'unit_price',
    PACKAGE_PRICE: 'package_price',
    ID_PACKAGE_TYPE: 'id_package_type',
    ID_FINAL_CUSTOMER: 'id_final_customer',
    ID_STOCK: 'id_stock',
    TAX_RATE: 'tax_rate',
    REQUESTED_AT: 'requested_at',
    AVAILABLE_AT: 'available_at',
    TRADE: 'trade',
    INVOICE_ADDRESS: 'invoice_address',
    DELIVERY_ADDRESS: 'delivery_address',
    ITEMS: 'items',
    LAST_CHANGE: 'last_change',
    SEND_AT: 'send_at',
    QUOTATION_REFERENCE: 'quotation_reference',
    REQUEST_REFERENCE: 'request_reference',
    ACTIVE_TO: 'active_to',
    DIVISIBILITY_LEVEL: 'divisibility_level',
    CUSTOMER_CODE: 'customer_code',
    SUPPLY_DURATION: 'supply_duration',
    SEND: 'send',
    SEQUENCE: 'sequence',
    DESIGNATION: 'designation',
    IS_VISIBLE: 'is_visible',
    IS_SERVICE: 'is_service',
    STOCK_RESERVE: 'stock_reserve',
    MASS: 'mass',
    DESCRIPTION_SHORT: 'description_short',
    DESCRIPTION_HTML: 'description_html',
    LIST_PRICE: 'list_price',
    EXT_REF: 'ext_ref',
    THUMBNAIL: 'thumbnail',
    AUTO_SUPPLY: 'auto_supply',
    PARAMETERS: 'parameters',
    COMPONENTS: 'components',
    BARCODES: 'barcodes',
    ID_RELATIVE: 'id_relative',
    ID_REPLACEMENT: 'id_replacement',
    MERGE_WITH: 'merge_with',
    CODE: 'code',
    SCALE: 'scale',
    PRICE: 'price',
    TRANSPORT_PRICE: 'transport_price',
    NAME: 'name',
    VAL: 'val',
    UNIT: 'unit',
    IMAGE: 'image',
    IS_NUMERIC: 'is_numeric',
    ARGS: 'args',
    LABEL: 'label'
};